/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
    padding: 90px 0;
}
.brand-grid {
    padding: 10px 0;
}

